export const environment = {
  production: false,

  clientName: 'demo',
  apiGatewayFront: 'https://siesaagp.palmaceite.com/',
  apiGatewayBackOffice: 'https://siesaagp.palmaceite.com/',
  apiUrl: 'https://siesaagp.palmaceite.com/',
  urlPath: 'https://siesaagp.palmaceite.com/',
  // apiGatewayFront: 'https://demoawsagp.siesaecommerce.com/',
  // apiGatewayBackOffice: 'https://demoawsagp.siesaecommerce.com/',
  // apiUrl: 'https://demoawsagp.siesaecommerce.com/',
  // urlPath: 'https://demoawsagp.siesaecommerce.com/',
  company: 1,
  customCss: false,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
  // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX

  siteKeyCaptcha: '6LeyCUEmAAAAAL88iVhNPdQU_Bl3-SBCW5eohhvl',
  //siteKeyCaptcha: '6LfbbBEjAAAAADhQgLXL89UR8UUdLB-zlmH1cQ-I',


  googleAnalyticsCode: 'UA-140785247-1',
};